import {ArticleStatus, Language, PaymentMethod, PaymentType, Role} from "@prisma/client";
import {JsonValue} from "@prisma/client/runtime/library";

export interface User {
    id: number;
    role: Role;
    cardCode: string;
    email: string;
    tempPassword: string | null;
    firstName: string;
    lastName: string;
    address: string | null;
    city: string | null;
    country: string | null;
    phoneNumber: string | null;
    birthDate: Date | null;
    isInformed: boolean;
    isInvolved: boolean;
    createdAt: Date;
    joinedAt: Date | null;
    tags?: Tag[];
    payments?: Payment[];
    hasPaidMembership?: boolean;
}

export interface UserBase {
    id: number;
    role: Role;
    cardCode: string | null;
    firstName: string;
    lastName: string;
}

export const RoleNames: { [key in Role]: string } = {
    [Role.ADMIN]: "Amministratore",
    [Role.EXECUTIVE]: "Direttivo",
    [Role.HONORARY]: "Membro onorario",
    [Role.MEMBER]: "Membro ordinario",
    [Role.SUPPORTER]: "Simpatizzante",
    [Role.DONATOR]: "Donatore",
    [Role.PENDING]: "In attesa",
};

export const PaymentMethodNames: { [key in PaymentMethod]: string } = {
    [PaymentMethod.PAYPAL]: "PayPal",
    [PaymentMethod.BANK_TRANSFER]: "Bonifico bancario",
    [PaymentMethod.CREDIT_CARD]: "Carta di credito",
    [PaymentMethod.CASH]: "Contanti",
};

export const PaymentTypeNames: { [key in PaymentType]: string } = {
    [PaymentType.MEMBERSHIP]: "Quota associativa",
    [PaymentType.DONATION]: "Donazione",
};

export const LanguageNames: { [key in Language]: string } = {
    [Language.ITALIAN]: "Italiano",
    [Language.BOSNIAN]: "Bosniaco",
    [Language.ENGLISH]: "Inglese",
};

export interface Idea {
    id: number;
    title: string;
    description: string;
    createdAt: Date;
    createdBy: User;
    updatedAt: Date | null;
    likes: Like[];
    anonymous: boolean;
    executive: boolean;
}

export interface Like {
    id: number;
    createdAt: Date;
    user: User;
    idea: Idea;
}

export interface Tag {
    id: number,
    createdAt: Date,
    updatedAt: Date | null,
    name: string,
    notes: string | null,
    users: UserBase[],
}

export enum MediaType {
    IMAGE = "IMAGE",
    VIDEO = "VIDEO",
    AUDIO = "AUDIO",
    DOCUMENT = "DOCUMENT"
}

export interface Media {
    id: number;
    mediaType: MediaType;
    title: string;
    description: string | null;
    url: string;
    createdAt: Date;
    updatedAt: Date | null;
}

export interface Payment {
    id: number;
    amount: number;
    user: UserBase;
    createdAt: Date;
    method: PaymentMethod;
    type: PaymentType;
    transactionId: string | null;
    project: Project | null;
    notes: string | null;
}

export interface Article {
    id: number;
    title: string;
    content: JsonValue;
    slug: string,
    createdBy: {
        id: number;
        firstName: string;
        lastName: string;
    };
    project: Project | null;
    preview: string;
    createdAt: Date;
    updatedAt: Date | null;
    language: Language;
    status: ArticleStatus;
}

export interface Project {
    id: number,
    title: string,
    code: string,
    isActive: boolean,
    createdAt: Date,
    updatedAt: Date | null,
}
