import countryData from "@/components/countries.json";
import {Language, PaymentMethod, PaymentType} from "@prisma/client";
import {LanguageNames, PaymentMethodNames, PaymentTypeNames} from "@/config/interfaces";
import toast from "react-hot-toast";
import {
    WiCloudy,
    WiDaySunny,
    WiFog,
    WiHail,
    WiRain,
    WiShowers,
    WiSleet,
    WiSnow,
    WiSnowWind,
    WiSprinkle,
    WiStormShowers,
    WiThunderstorm
} from "react-icons/wi";

function capitalizeWords(str: string) {
    return str.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}

export function formatBytes(a: number, b = 2) {
    if (!+a) return "";
    const c = 0 > b ? 0 : b, d = Math.floor(Math.log(a) / Math.log(1000));
    return `${parseFloat((a / Math.pow(1000, d)).toFixed(c))} ${["B", "KB", "MB", "GB", "TB"][d]}`
}

export function downloadCsv(data: string, filename: string) {
    const blob = new Blob([data], {type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    toast.success('File CSV scaricato con successo!');
}

export function formatDate(date: string) {
    return new Date(date).toLocaleString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });
}

export const countries = Object.entries(countryData)
    .filter(([, data]: any) => data['italian_country_name_1'])
    .map(([code, data]: any) => ({
        code, label: capitalizeWords(data['italian_country_name_1']),
    }));

export const paymentTypes = Object.values(PaymentType).map((type) => ({
    value: type,
    label: PaymentTypeNames[type as PaymentType],
}));

export const paymentMethods = Object.values(PaymentMethod).map((method) => ({
    value: method,
    label: PaymentMethodNames[method],
}));

export const languages = Object.values(Language).map((language) => ({
    value: language,
    label: LanguageNames[language],
}));

export function createSlug(str: string) {
    return str.normalize('NFD').toLowerCase().replace(/ /g, "-").replace(/[^\w-]+/g, "");
}

export const weatherIcons = {
    113: WiDaySunny,
    116: WiCloudy,
    119: WiCloudy,
    122: WiFog,
    143: WiSprinkle,
    176: WiSprinkle,
    179: WiSnow,
    182: WiSleet,
    185: WiSleet,
    200: WiStormShowers,
    227: WiSnow,
    230: WiSnowWind,
    248: WiFog,
    260: WiFog,
    263: WiSprinkle,
    266: WiSprinkle,
    281: WiSleet,
    284: WiSleet,
    293: WiSprinkle,
    296: WiSprinkle,
    299: WiShowers,
    302: WiShowers,
    305: WiRain,
    308: WiRain,
    311: WiSleet,
    314: WiSleet,
    317: WiSleet,
    320: WiSleet,
    323: WiSnow,
    326: WiSnow,
    329: WiSnow,
    332: WiSnow,
    335: WiSnow,
    338: WiSnow,
    350: WiHail,
    353: WiSprinkle,
    356: WiRain,
    359: WiRain,
    362: WiSleet,
    365: WiSleet,
    368: WiSnow,
    371: WiSnow,
    374: WiSleet,
    377: WiHail,
    386: WiStormShowers,
    389: WiThunderstorm,
    392: WiStormShowers,
    395: WiStormShowers,
}